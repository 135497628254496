<template>
  <div class="association">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <header class="header">
        <img src="./img/banner.png" alt="图片加载失败..." />
      </header>
      <div class="createBanner" @click="toTalent()">
        <img src="./img/create.png" alt="" />
      </div>
      <div class="tab_box">
        <div class="in_box">
          <div class="item">
            最新
            <div class="arrow">
              <img
                v-if="newFlag == 0"
                class="top"
                src="./img/active_t.png"
                alt=""
              />
              <img
                v-else
                @click="tabClick(1, 0)"
                class="top"
                src="./img/top.png"
                alt=""
              />
              <img
                v-if="newFlag == 1"
                class="bottom"
                src="./img/active_b.png"
                alt=""
              />
              <img
                v-else
                @click="tabClick(1, 1)"
                class="bottom"
                src="./img/bottom.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="item">
            积分
            <div class="arrow">
              <img
                v-if="pointsFlag == 0"
                class="top"
                src="./img/active_t.png"
                alt=""
              />
              <img
                v-else
                @click="tabClick(2, 0)"
                class="top"
                src="./img/top.png"
                alt=""
              />
              <img
                v-if="pointsFlag == 1"
                class="bottom"
                src="./img/active_b.png"
                alt=""
              />
              <img
                v-else
                @click="tabClick(2, 1)"
                class="bottom"
                src="./img/bottom.png"
                alt=""
              />
            </div>
          </div> -->
          <div class="item">
            人数
            <div class="arrow">
              <img
                v-if="menFlag == 0"
                class="top"
                src="./img/active_t.png"
                alt=""
              />
              <img
                v-else
                @click="tabClick(3, 0)"
                class="top"
                src="./img/top.png"
                alt=""
              />
              <img
                v-if="menFlag == 1"
                class="bottom"
                src="./img/active_b.png"
                alt=""
              />
              <img
                v-else
                @click="tabClick(3, 1)"
                class="bottom"
                src="./img/bottom.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="item-l"><img :src="item.picture" alt="" /></div>
            <div class="item-r">
              <div class="name">{{ item.associationName }}</div>
              <div class="tag">{{ item.keyword }}</div>
              <div class="detail_box">
                <img class="num_icon" src="./img/menNum.png" alt="" />
                <div class="num">{{ item.memberNum }}人</div>
                <!-- <img class="point_icon" src="./img/point.png" alt="" />
                <div class="point">{{ item.points }}分</div> -->
              </div>
              <div class="btn_box orange" v-if="item.isJoin == 1">已加入</div>
              <div class="btn_box withe" v-else-if="item.isJoin == 2">
                审核中
              </div>
              <div class="btn_box empty" v-else-if="item.isJoin == 3">
                审核失败
              </div>
              <div
                class="btn_box orange"
                @click.stop="applyJonin(item)"
                v-else-if="item.isJoin == 0"
              >
                加入
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getAssociaListUrl } from "./api.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "association",
  components: {},
  data() {
    return {
      newFlag: null,
      pointsFlag: null,
      menFlag: null,

      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  mounted() {
    gloabalCount("", 7, 1);
  },
  methods: {
    toTalent() {
      this.$router.push({
        name: "talent",
      });
    },
    tabClick(index, type) {
      if (index == 1) {
        this.newFlag = type;
        this.pointsFlag = null;
        this.menFlag = null;
        this.onRefresh();
      } else if (index == 2) {
        this.newFlag = null;
        this.pointsFlag = type;
        this.menFlag = null;
        this.onRefresh();
      } else if (index == 3) {
        this.menFlag = type;
        this.newFlag = null;
        this.pointsFlag = null;
        this.onRefresh();
      }
    },
    applyJonin(data) {
      //跳转加入申请页面
      this.$router.push({
        name: "applyJoin",
        query: {
          associationId: data.id,
        },
      });
    },
    toDetail(item) {
      //跳转社群详情
      let params = {
        id: item.id,
        isJoin: item.isJoin,
      };
      if (window.location.origin == "https://mapi.xydata.cc") {
        this.$router.push({
          name: "associationDetail",
          query: {
            id: item.id,
          },
        });
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/associationDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      }
    },
    // 加载列表数据
    async onLoad() {
      if (await toRegister(this.$route.path, this.$route.query, "社群")) {
        this.getAssociationList();
      }
    },
    onRefresh() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    getAssociationList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        tenantId: this.tenantId,
        timeSort: this.newFlag,
        pointSort: this.pointsFlag,
        personSort: this.menFlag,
        // associationId: "",
        // parentId: "",
        // associationLevel: 1,
        // tenantId: this.tenantId,
      };
      this.$axios
        .get(`${getAssociaListUrl}`, {
          params: params,
        })
        .then((res) => {
          this.isLoading = false;
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.picture) {
                item.picture = this.$handleImg(204, 204, item.picture);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.association {
  padding-top: 18px;

  .tab_box {
    width: 100%;
    height: 92px;
    padding: 0 28px;
    box-sizing: border-box;
    margin: 30px 0;

    .in_box {
      width: 100%;
      height: 100%;
      padding: 0 50px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      display: flex;
      font-size: 30px;
      font-weight: 600;
      color: #fe9615;

      .item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        /*水平主轴居中*/

        .arrow {
          margin-left: 30px;
          width: 14px;
          height: 18px;
          position: relative;

          .top {
            position: absolute;
            top: 0;
            left: 0;
            width: 14px;
            height: 6px;
          }

          .bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 14px;
            height: 8px;
          }
        }
      }
    }
  }
  .createBanner {
    width: 710px;
    height: 142px;
    margin: 0 auto;
    margin: 32px;
    img {
      width: 690px;
      height: 100%;
    }
  }
  header {
    margin: 0 auto;
    width: 690px;
    height: 334px;

    img {
      width: 690px;
      height: 100%;
    }
  }

  .main {
    padding: 32px;
    padding-top: 0;

    .item {
      height: 204px;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      display: flex;
      margin-bottom: 32px;

      .item-l {
        width: 204px;
        height: 204px;
        border-radius: 16px 0 0 16px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 16px 0 0 16px;
        }
      }

      .item-r {
        flex: 1;
        box-sizing: border-box;
        position: relative;

        .name {
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          position: absolute;
          top: 24px;
          left: 22px;
        }

        .tag {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 66px;
          left: 22px;
        }

        .detail_box {
          width: calc(100% - 194px);
          height: 62px;
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;

          .num_icon {
            width: 24px;
            height: 16px;
            margin-left: 20px;
          }

          .num {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            margin-left: 6px;
          }

          .point_icon {
            margin-left: 34px;
            width: 18px;
            height: 16px;
          }

          .point {
            margin-left: 6px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .btn_box {
          width: 194px;
          height: 62px;
          text-align: center;
          line-height: 62px;
          font-size: 26px;
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px 0px 16px 0px;
          position: absolute;
          bottom: 0;
          right: 0;
        }

        .orange {
          color: #fff;
          background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
        }

        .withe {
          border: 2px solid;
          color: #fe9919;
        }

        .empty {
          background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
          color: #a8a8a8;
        }
      }
    }
  }
}
</style>
